import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import theme from '../utils/theme';
import { VoidTestBookingForm } from './VoidTestBookingForm';

const oauthClientIdForTripteasePaidSearch = '470068314924-ikjk0jckfj967k3viqo1tq6i3b3vpm1u.apps.googleusercontent.com';

export const App: FC = () => {
  const handleGoogleLoginResponse = (response: CredentialResponse) => {
    console.log(response);

    if (response.credential) {
      setToken(response.credential);
    }
  };

  const [token, setToken] = useState<string | undefined>(undefined);

  return (
    <GoogleOAuthProvider clientId={oauthClientIdForTripteasePaidSearch}>
      <LayoutContainer>
        <PageContainer>
          <h1>Paid Search Back Office</h1>
          {!token && (
            <GoogleLogin
              onSuccess={handleGoogleLoginResponse}
              onError={() => {
                console.log('Login Failed');
              }}
              size="large"
            />
          )}
          {token && (
            <>
              <VoidTestBookingForm authToken={token} />
            </>
          )}
        </PageContainer>
      </LayoutContainer>
    </GoogleOAuthProvider>
  );
};

const PageContainer = styled.div`
  width: 800px;
  margin-left: 20px;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  font-family: ${theme.fontFamily};
  margin-bottom: 100px;
`;
